








































































































































































































































































































































































































































































































































import {Component, Vue } from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import buyAddressApi from "@/common/api/business";
import userCost from "@/components/userCost.vue";
import reservation from "@/components/reservation.vue";
import coach from "@/components/coach.vue";
import gods from "@/components/gods.vue";
import billPrint from "@/components/print.vue";
import mergePools from "@/views/business/business/mergePools.vue";
import cancelMergePools from "@/views/business/business/cancelMergePools.vue";
import goodSale from "@/views/business/business/goodSale.vue";
import goodsDispatchDetails from "@/views/business/business/goodsDispatchDetails.vue";

export const pageApiList = getPageAPIs(buyAddressApi);

@Component({
  components: {
    userCost,
    reservation,
    gods,
    coach,
    billPrint,
    mergePools,
    cancelMergePools,
    goodSale,
    goodsDispatchDetails

  },
})
export default class TechnicianList extends Vue {
  private buyAddressApi: any = new buyAddressApi();
  private isLoading:boolean = false
  private title: string = "";
  private show_table: boolean = false;
  private invoicing: boolean = false;
  private pay: boolean = false;
  private scanCode: boolean = false;
  private openVisible: boolean = false;
  private groupBuyingOpeningVisible: boolean = false;
  private renewal: boolean = false;
  private printDialog: boolean = false;
  private conversion: boolean = false;
  private tableDataTreat: any = [];
  private opentable: any = {
    open_type:2,
    pool_id: "",
    time: 12,
    phone: "",
    deposit_price: "",
    order_id: "",
  };
  private coachform: any = {};
  private print_table_name:string=''
  //助教下单
  private assistantorder(): void {
    // this.coachform.order_id = this.showinfo.id;
    this.coachform.order_id = this.orderlist.processing.id;
    console.log(this.coachform);
    this.buyAddressApi.assistantorder(this.coachform, () => {
      Util.showToast("下单成功");
      this.coachshow = false;
      this.orderindex();
    });
  }

  private settime(type: string): void {
    if (type === 'add') {
      if(this.opentable.time==48){
        this.$message.error('最多48小时')
        return
      }
      this.opentable.time++;
    } else {
      if(this.opentable.time==1){
        this.$message.error('最少1小时')
        return
      }
      this.opentable.time--;
    }
  }

  private chargebill: any = {};
  private good_list: any = [];

  private charge(): void {

    this.buyAddressApi.charge(
        {
          merge_order_id: this.payInfo.merge_order_id,
        },
        (res: any) => {
          this.chargebill = res.data;
          this.good_list = res.data.good_list;
          this.tableDataTreat = [
            {
              name: "原始 - 台桌订单费用",
              money: res.data.order_price,
            },
            {
              name: "原始 - 商品订单费用",
              money: res.data.good_price,
            },
            {
              name: "原始 - 助教订单费用",
              money: res.data.assistant_price,
            },
            {
              name: "原始 - 总费用",
              money: res.data.total_price,
            },
            {
              name: "实际支付 - 台桌订单费用",
              money: res.data.actual_order_price,
            },
            {
              name: "实际支付 - 商品订单费用",
              money: res.data.actual_good_price,
            },
            {
              name: "实际支付 - 助教订单费用",
              money: res.data.actual_assistant_price,
            },
            {
              name: "实际支付 - 总费用",
              money: res.data.actual_total_price,
            },
          ];
          this.printDialog = true;
        }
    );
  }

  private cost: string = "";
  private payInfo: any = {};

  //原结算接口
  private overorder(): void {
    this.mobileOptions = []
    this.is_vip_pay = 0
    this.buyAddressApi.overorder(
        {
          // order_id: this.mergeOrderId,
          order_id: this.orderlist.processing.id,
        },
        (res: any) => {
          console.log('结算',res.data)
          this.pay = true;
          this.userinfo.id = "";
          this.msg = "";
          this.show_table = false;
          this.invoicing = false;
          this.payInfo = res.data;
        }
    );
  }


  private typeName: any = [
    {
      name: "台桌订单费用",
      type: "order_price",
    },
    {
      name: "商品费用",
      type: "good_price",
    },
    {
      name: "教练费用",
      type: "assistant_price",
    },
  ];
  private orderinfo: any = {};

  private orderboot(type: number): void {
    var reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (this.opentable.phone != "") {
      if (!reg_phone.test(this.opentable.phone)) {
        Util.showToast("请输入正确的手机号", "warning");
        return;
      }
    }
    if ( !(this.opentable.time>=1 && this.opentable.time<=48) ) {
      Util.showToast("时长范围为1-48小时", "warning");
      return;
    }
    this.biz_type = type;
    if (this.tableinfo.is_status == 2 || this.tableinfo.is_status == 3) {
      this.opentable.order_id = this.order_id;
    }
    this.buyAddressApi.ordercheck(
        {
          ...this.opentable,
          pool_id: this.pool_id,
        },
        (res: any) => {
          if (res.data.isOver == 0) {
            this.opentable.pool_id = this.pool_id;
            this.buyAddressApi.orderboot(this.opentable, (res: any) => {
              this.order_id = res.data.order_id;
              this.orderinfo = res.data;
              this.renewal = false;
              this.openVisible =false
              this.show_table = false;
              this.getTable();
            });
          } else if (res.data.isOver == 3) {
            // Util.showToast("暂无可约时间");
            Util.showToast("此球桌已被占用，请选择其他球桌");
          } else if (res.data.isOver == 1) {
            // Util.showToast("预约时间被占用");
            Util.showToast("此球桌已被占用，请选择其他球桌");
          }
        }
    );
  }

  private code: string = "";
  private is_vip_pay: number = 0;

  private orderpay(print = false): void {
    console.log('手机号码',this.opentable.phone,this.is_vip_pay)
    //会员支付
    if(this.opentable.phone.length!=11 && this.check===4){
      Util.showToast("请输入正确的手机号点击查询再结账", "warring");
      return
    }
    //微信和支付宝-组合支付
    if( this.check===1 && this.is_vip_pay>0 && this.opentable.phone.length!=11){
      Util.showToast("请输入正确的手机号点击查询再结账", "warring");
      return
    }

    if ( this.check == 3 || this.check == 4 || this.check == 5 || this.check == 6) {
      if (
          this.payInfo.total_price > this.payInfo.order_price &&
          this.check == 5
      ) {
        Util.showToast("有其他费用，请使用组合支付", "warring");
      } else {
        if(this.isLoading){
          return;
        }
        this.isLoading  = true
        this.buyAddressApi.orderpay(
            {
              merge_order_id: this.payInfo.merge_order_id,
              pay_type: this.check,
              is_vip_pay: (this.check === 3 || this.check === 6) ?0:this.is_vip_pay,
              user_id: this.userinfo.id,
              // code: this.code,
            },
            (res: any) => {
              Util.showToast("预支付成功");
              if (print) {
                this.charge();
                this.print_table_name = this.tableinfo.name
              }
              this.userinfo.id = "";
              this.msg = "";
              this.renewal = false;
              this.show_table = false;
              this.pay = false;
              this.code = "";
              this.invoicing = false;
              this.is_vip_pay = 0;
              setTimeout(()=>{
                this.isLoading = false
              },300)
              this.getTable();
            },()=>{
              setTimeout(()=>{
                this.isLoading = false
              },300)
            }
        );
      }
    } else {
      this.scanCodePay(print);
    }
  }

  private topStatus(status:number):string{

    return `top${status}`
  }

  private orderprint(): void {
    this.printDialog = true;
  }

  private auth_code: string = "";
  private biz_type: number = 3;

  private payrequest(print = false): void {
    this.buyAddressApi.payrequest(
        {
          order_id: this.payInfo.merge_order_id,
          pay_type: this.check,
          biz_type: this.biz_type,
          auth_code: this.auth_code,
          is_vip_pay: this.is_vip_pay,
          user_id: this.userinfo.id,
          // code: this.code,
        },
        (res: any) => {
          if (print) {
            this.charge();
          }
          Util.showToast("支付成功");
          this.is_vip_pay = 0;
          this.code = "";
          this.renewal = false;
          this.show_table = false;
          this.pay = false;
          this.invoicing = false;
          this.scanCode = false;
          this.getTable();
        }
    );
  }

  private handleClose(done: any) {
    if (this.tableinfo.is_status == 1) {
      this.cancelpay();
    } else {
      this.$confirm("确认关闭？")
          .then((_) => {
            done();
            this.getTable();
          })
          .catch((_) => {
          });
    }
  }

  private getscanCode(print = false): void {
    let that = this;
    let str = "";
    document.onkeydown = function (e: any) {
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            that.auth_code = str;
            str = "";
            that.payrequest(print);
          }
        }
      }
    };
  }
  private payquery(): void {
    this.buyAddressApi.payquery(
        {
          order_id: this.payInfo.merge_order_id,
          biz_type: this.biz_type,
        },
        (res: any) => {
          if (res.code == 200) {
            Util.showToast("支付成功");
            this.code = "";
            this.renewal = false;
            this.show_table = false;
            this.pay = false;
            this.scanCode = false;
            this.invoicing = false;
            this.getTable();
          }
        }
    );
  }

  private cancelpay(): void {
    console.log(this.opentable);
    this.buyAddressApi.cancelpay(
        {
          order_id: this.order_id,
        },
        () => {
          Util.showToast("取消成功");
          this.renewal = false;
          this.show_table = false;
          this.pay = false;
          this.scanCode = false;
          this.getTable();
        }
    );
  }

  private pay_type: any = [
    {
      hidden:this.$store.state.platformType===2,
      check: 4,
      name: "会员",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 5,
      name: "单店卡",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 1,
      name: "微信和支付宝",
      icon: require("@/assets/dialog/icon／微信@2x.png"),
    },
    {
      check: 6,
      name: "现金",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
    {
      check: 3,
      name: "线下收款码",
      icon: require("@/assets/dialog/icon／现金@2x.png"),
    },
  ];
  private check: number = 1;

  private changePayType(checkType: number): void {
    this.check = checkType;
  }

  private scanCodePay(print = false): void {
    this.scanCode = true;
    this.getscanCode(print);
  }

  private tableinfo: any = {
    daytime: {},
    nighttime: {},
  };
  private pool_id: string = "";
  private orderlist: any = {
    processing:{}
  };
  private godsMoney: number = 0;
  private assistantrMoney: number = 0;

  private orderindex() {
    this.buyAddressApi.orderindex({pool_id: this.pool_id,},
        (res: any) => {
          this.show_table = true;
          this.orderlist = res.data;
          // this.$set(this.orderlist,'goods',res.data.goods)
          // this.$set(this.orderlist,'assistant',res.data.assistant)
          this.godsMoney = 0;
          this.assistantrMoney = 0;
          if (this.tableinfo.is_status == 2 || this.tableinfo.is_status == 3) {
            this.order_id = this.orderlist.processing.id;
            for (let i of this.orderlist.goods) {
              this.godsMoney += Number(i.actual_price);
            }
            for (let i of this.orderlist.assistant) {
              this.assistantrMoney += Number(i.total_price);
            }
          }
        },()=>{},true);
  }
  private order_id: string = "";
  private tableView(item: any): void {
    this.checkUsePools = [];
    this.opentable.phone = "";
    console.log(item);
    this.tableinfo = item;
    this.pool_id = item.id;
    this.title = item.name;
    this.orderindex();

  }

  private form: any = {
    nickname: "",
    qrcodeNickname: "",
  };

  // private activated(): void {
  //   this.getTable();
  // }
  private created(): void {
    this.getTable();
  }

  private activated(): void {
    console.log('组件实例激活。');
    this.startCountDown();
  }

  deactivated () {
    // 实例销毁之前调用。主要用于销毁时需要清理的事件监听器、定时器等
    console.log('组件实例即将销毁。');
    clearInterval(this.setInterval)
  }

  private setInterval:any = null
  private startCountDown() {
    this.setInterval = setInterval(()=>{
      this.getTable()
    }, 10000);
  }

  private getTable(): void {
    this.buyAddressApi.pool({}, (res: any) => {
      this.tableData = res.data.list;
      this.getOrderGoodsSale()
    });
  }

  private orderGoodsSaleList:any = []
  private getOrderGoodsSale(){
    this.buyAddressApi.orderGoodsSale({}, (res: any) => {
      this.orderGoodsSaleList =  res.data.list
      const len = this.tableData.length
      for(const item of this.orderGoodsSaleList){
        for(let i = 0;i<len;i++){
          const table = this.tableData[i]
          if(item.desk_id==table.id){
            table.goods_sale_num = item.num
            table.goods_sale_created_at = item.created_at
            this.$set(this.tableData,i,table)
          }
        }
      }
    });
  }

  private onShowGoodsDispatch(table:any):void {
    this.$refs.goodsDispatch.show(table)
  }
  private restForm(): void {
    this.opentable = new Util().clearObject(this.opentable);
    this.opentable.time = 12;
    this.opentable.open_type  = 2
  }

  private tableData: any[] = [];

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private coachshow: boolean = false;

  private selectcoach(): void {
    this.coachshow = true;
    this.assistantshow();
  }

  private msg: string = "";
  private input: number = 0;
  private userinfo: any = {
    type: 0,
  };

  //续费确定
  private onConfirmRenewal():void{
    console.log('续费',this.orderlist.processing.id,this.opentable.time)
    this.buyAddressApi.orderRenew({
      order_id:this.orderlist.processing.id,
      time:this.opentable.time
    },(res:any)=>{
      Util.showToast("续费成功");
      this.orderindex()
      this.getTable()
      this.renewal = false
    })
  }

  //会员查询
  private searchmember(): void {
    this.msg = "";
    this.buyAddressApi.searchmember(
        {mobile: this.opentable.phone,},
        (res: any) => {
          console.log('查询用户信息', res)
          this.userinfo = res.data;
          if (res.data.nickname) {
            if (res.data.type != 0) {
              if(this.$store.state.platformType===1){
                this.msg = `用户${res.data.nickname}会员余额：￥${res.data.money},单店卡：￥${res.data.shop_money}`;
              }else{
                this.msg = `用户${res.data.nickname}单店卡：￥${res.data.shop_money}`;
              }
            } else {
              if (res.data.shop_money != 0) {
                this.msg = `用户${res.data.nickname}单店卡：￥${res.data.shop_money}`;
              } else {
                this.msg = "该用户还不是会员";
              }
            }
          } else {
            this.msg = "未查询到此用户";
          }
        }
    );
  }

  //助教下拉
  private assistant: any = [];

  private assistantshow(): void {
    this.buyAddressApi.assistantshow({}, (res: any) => {
      this.assistant = res.data.list;
    });
  }

  //转台
  private poolshow: boolean = false;
  private poolist: any = [];
  private poolsform: any = {
    pool_id:null
  };

  private changePools(): void {
    this.buyAddressApi.changePools(
        {
          pool_id: this.pool_id,
        },
        (res: any) => {
          this.poolist = res.data.list;
        }
    );
  }

  private orderchange(): void {
    this.buyAddressApi.orderchange(
        {
          // order_id: this.showinfo.id,
          order_id: this.orderlist.processing.id,
          pool_id: this.poolsform.pool_id,
        },
        () => {
          this.poolsform.pool_id = "";
          Util.showToast("转台成功");
          this.poolshow = false;
          this.show_table = false;
          this.getTable();
        }
    );
  }

  private usePoolsList: any = [];
  private checkUsePools: any = [];

  private usePools() {
    this.buyAddressApi.usePools(
        {
          pool_id: this.pool_id,
        },
        (res: any) => {
          this.usePoolsList = res.data.list;
        }
    );
  }

  private mergeOrderId: any = [];
  private processing_merge: any = [];
  private assistant_merge: any = [];
  private goods_merge: any = [];

  private processing_merge_price: number = 0;
  private assistant_merge_price: number = 0;
  private goods_merge_price: number = 0;


  private checkout(): void {
    this.processing_merge = [];
    this.assistant_merge = [];
    this.goods_merge = [];
    this.processing_merge_price = 0;
    this.assistant_merge_price = 0;
    this.goods_merge_price = 0;
    this.buyAddressApi.orderBill({order_id:this.orderlist.processing.id},
        (res: any) => {
          this.processing_merge = res.data.order;
          this.assistant_merge = res.data.assistant;
          this.goods_merge = res.data.goods;
          console.log('processing_merge',this.processing_merge)
          for (let i of res.data.order) {
            this.processing_merge_price += Number(i.price);
          }
          for (let i of res.data.assistant) {
            this.assistant_merge_price += Number(i.total_price);
          }
          for (let i of res.data.goods) {
            this.goods_merge_price += Number(i.actual_price);
          }
          this.invoicing = true;
        },(err:any)=>{
          console.log('err',err)
          if(err.code===405){
            this.$confirm(`当前订单是自助开台订单，是否确定转化为收银台订单结算？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.buyAddressApi.changeOrder({
                order_id:this.orderlist.processing.id
              },((res:any)=>{
                Util.showToast("转化成功");
                this.checkout()
              }))
            })
          }
        }
    );
  }

  private btninvoicing(): void {

    this.checkout();
  }
  private onChangePool():void{
    this.poolshow = true
    this.poolsform.pool_id = null
    this.changePools()
  }


  private onMergePools():void{
    let orderInfo = this.orderlist.processing || {}
    this.$refs.mergePools.show(this.pool_id,orderInfo)
  }
  private onCancelMergePools():void {
    let orderInfo = this.orderlist.processing || {}
    this.$refs.cancelMergePools.show(this.pool_id, orderInfo)
  }
  private classify: any = [];
  private classifys(): void {
    this.buyAddressApi.classifys({}, (res: any) => {
      this.classify = res.data.list;
      this.msg = "";
    });
  }

  private totalConsumptionMoney():any{
    let total = 0
    if(this.orderlist.order && this.orderlist.order.length){
      this.orderlist.order.forEach((item:any)=>{
        total += Number( item.price)
      })
      total = total + Number(this.godsMoney) +  Number(this.assistantrMoney)
      return total.toFixed(2)
    }
    return total.toFixed(2)
  }
  private onShowGoodsSale():void{
    // this.$refs.goodSale.show(this.showinfo.id)
    this.$refs.goodSale.show(this.orderlist.processing.id,this.tableinfo)
  }
  private goodSaleCallback():void{
    this.orderindex();
  }
  private goodsDispatchCallback():void{
    this.getTable();
  }

  /**
   * 并台成功
   * */
  private onConfirmGroupBuying():void{
    const order_open_classify_id = this.opentable.order_open_classify_id
    if(!order_open_classify_id){
      Util.showToast('券码必填！','warning')
      return
    }
    this.biz_type = 3;
    this.buyAddressApi.verifyCard({
      receipt_code:order_open_classify_id,
      type:0
    },(verifyResponse:any)=>{
      console.log('预核销结果',verifyResponse)
      const result = verifyResponse.data
      const cardTypeName = result.type === 3 ? '美团券' : (result.type === 4 ? '抖音券' : '' )
      this.$confirm(`您已选择“${result.name}”，时长${result.value}分钟是否核销开台`, `${cardTypeName}核销提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.buyAddressApi.ordercheck(
            {
              pool_id: this.pool_id,
              minute:result.value
            },(res:any)=>{
              if (res.data.isOver == 0) {
                this.buyAddressApi.verifyCard({
                  receipt_code:order_open_classify_id,
                  type:1
                },(res2:any)=>{
                  console.log('核销成功',res2)
                  this.groupBuyingOpeningVisible = false;
                  this.buyAddressApi.orderboot({
                    pool_id:this.pool_id,
                    open_type:result.type,
                    order_open_classify_id:res2.data.order_open_classify_id
                  }, (res: any) => {
                    this.order_id = res.data.order_id;
                    this.orderinfo = res.data;
                    this.show_table = false;
                    this.getTable();
                  });
                })
              } else if (res.data.isOver == 1 ||res.data.isOver == 3) {
                // Util.showToast("暂无可约时间");
                Util.showToast("此球桌已被占用，请选择其他球桌");
              }
            }
        )


      })
    })

  }
  /**
   * 分钟转小时，保留两位小数点
   * */
  private minutesToHours(minutes:any) {
    var hours = minutes / 60;
    var factor = Math.pow(10, 2);
    var result = Math.floor(hours * factor) / factor;
    return result;
  }

  /**
   * 并台成功
   * */
  private mergePoolsSuccess():void{
    this.show_table = false
    this.getTable()
  }
  /**
   * 取消并台成功
   * */
  private cancelMergePoolsSuccess():void{
    this.getTable()
    this.orderindex()
  }

  private mobileOptions: any = [];
  private remoteLoading:boolean = false
  private remoteMethod(query:any): void {
    this.opentable.phone = query
    console.log('手机号999',this.opentable.phone)
    if (query.length==4||query.length==11) {
      this.remoteLoading = true
      this.buyAddressApi.searchMobile({mobile:query},(res:any)=>{
        this.remoteLoading = false
        console.log('手机号结果',res)
        this.mobileOptions = res.data.list
      })
      if(query.length==11){
        this.searchmember()
      }
    } else {
      this.mobileOptions = [];
    }
  }

  private onSelectMobile(mobile:string):void{
    console.log('mobile',mobile)
    this.searchmember()
  }

}
